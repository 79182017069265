.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

html, body{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.secondary-item .MuiListItemText-secondary {
  color: red;
}

/** * Extracted from: SweetAlert * Modified by: Istiak Tridip */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: '';
  height: 100px;
  position: absolute;
  background: #fff;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, .5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #fff;
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}


.lol {
  margin: 5px !important;
  width: 50px !important;
  height: 50px !important;
  font-weight: bold !important;
  color: white !important;
  border-radius: 5px !important;
  opacity: 40%;
  font-size: 21px !important;
  background: dimgray !important;
  cursor: not-allowed;
  border: 0;
}

.MuiTypography-caption {
  width: 40px !important;
}

.info-page .MuiListItemText-root {
  word-break: break-word;
}

.css-1n2mv2k {
  justify-content: space-between !important;
}

.PrivatePickersSlideTransition-root {
  overflow-x: unset !important;
  min-height: unset !important;
  height: 312px !important;
}

.MuiCalendarPicker-root {
  max-height: unset !important;
  width: unset !important;
}

.css-mvmu1r {
  justify-content: space-between !important;
}

.MuiPickersDay-dayWithMargin {
  border-radius: 2px !important;
  margin: 5px !important;
  width: 50px !important;
  height: 50px !important;
  font-weight: bold !important;
  color: #53537d !important;
  border-radius: 5px !important;
  font-size: 21px !important;
  background: #f4f5f9 !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
 border: unset !important;
}

.css-l0iinn {
  pointer-events: none;
}

.PrivatePickersYear-yearButton.Mui-selected {
  border-radius: 5px !important;
  color: black;
}

.PrivatePickersYear-yearButton {
  border-radius: 5px !important;
  color: black;
}

.calendar-time-date {
  margin: 2px !important;
  color: white !important;
  pointer-events: all !important;
}

.MuiPickersDay-dayWithMargin:active {
  background: #30415c !important;
  color: wheat !important;
}

.Mui-selected {
  background: #FDFDFD !important;
  border: 3px solid red !important;
}

.info-page .MuiGrid-item:nth-child(2n-1) {
  text-align-last: right !important;
}

body {
  background: #FDFDFD;
}

.realign-media {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 899px) {
  .info-page .MuiListItem-root {
    padding: 8px !important;
  }
  .realign-media {
    justify-content: unset !important;
  }

  .realign-media > div {
    padding-top: 0 !important;
    max-width: 20% !important;
  }

  .realign-media > div > button {
    line-height: 1 !important;
  }

  .evropa-bg  img {
    width: 100px !important;
  }
}


@media screen and (min-width: 899px) {
  .realign-media {
    justify-content: center !important;
  }
}

.card-style {
  height: calc(100vh / 2);
  /*width: calc(100vw / 2);*/
  height: fit-content;
}

.typo-text {
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 24px !important;
  transition-duration: 500ms;
}

.sub-text {
  text-transform: none !important;
  color:  lightgray;;
}

.izberete-datum-i-vreme {
  color: #395177 !important;
  font-weight: bold !important;
  margin-top: 32px !important;
}

@media screen and (max-width: 500px) {
  .MuiCalendarPicker-root {
    width: 450px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .MuiCalendarPicker-root {
    width: 350px !important;
  }

  .se-soglasuvam-c {
    text-align: justify !important;
    font-size: 0.9rem !important;
  }

  .MuiCalendarPicker-root .PrivatePickersSlideTransition-root {
    height: 300px !important;
  }
}

.MuiPickersDay-dayWithMargin {
  margin: 3px !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 19px !important;
}

.lol {
  margin: 3px !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px !important;
}

@media screen and (max-width: 899px) {

  .MuiStepLabel-iconContainer > div {
    margin: 3px !important;
    width: 40px !important;
    height: 40px !important;
    font-size: 19px !important;
  }

  .small-heading-font {
    font-size: 16px !important;
    margin-top: 0px !important;
  }

  .typo-text {
    font-size: 18px !important;
  }

  .izberete-datum-i-vreme {
    font-size: 18px !important;
    margin-top: 0 !important;
  }

  .hide {
    display: none;
  }

  .MuiCalendarPicker-root .MuiTypography-caption {
    height: fit-content !important;
    width: 40px !important;
  }
}

.box-style {
  margin-top: 15px;
  margin-bottom: 15px;
}

.checkbox {
  display: flex;
  align-items: center;
}

.button-style {
  width: -webkit-fill-available;
  display: block !important;
  margin: 5px !important;
  border-radius: 12px !important;
  padding: 25px !important;
  background-color: #395177 !important;
  transition-duration: 500ms;
}

.button-style:hover {
  background-color: #30415c !important
}

.button-style:hover .typo-text{
  font-size: 26px !important;
  transition-duration: 500ms;
}

.align-items {
  align-items: center;
}

.mt-0 {
  margin-top: 0px !important;
}

.evropa-bg {
  background: linear-gradient(90deg, rgba(19,27,37,1) 0%, rgba(46,66,91,1) 50%, rgba(19,27,37,1) 100%) !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: inherit !important;
}